import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { H4, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	ColorBlack,
	ColorBlue,
	ColorGreen,
	ColorGreenLighter,
	ColorPink,
	ColorPurple,
	ColorRed,
	ColorYellow,
} from "@siteimprove/fancylib/design-tokens/variables";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Header,
	DocsTable,
	Code,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import {
	MultiSegmentProgressBar,
	MultiSegmentProgressBarProps,
} from "./multi-segment-progress-bar";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "Multi Segment Progress Bar",
	notepad: "https://hackmd.io/Q0Drm3w-QLyFzNx1I9KNnA",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Multi Segment Progess Bar"
				subTitle="Visually communicate the progress of a specific process and its sub-processes."
			/>
			<ContentSection>
				<TextContainer article>
					<LabWarning />
					<ImportExample lab component={MultiSegmentProgressBar} />
					<Header.H2>Multi-segment progress bar vs. Progress bar</Header.H2>
					<DocsTable>
						<thead>
							<tr>
								<th>Component</th>
								<th>Purpose</th>
								<th>Usage guideline</th>
								<th>Examples</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Multi-Segment Progress Bar</td>
								<td>Shows progress through distinct steps/phases.</td>
								<td>Helps users understand where they are in a process and what comes next.</td>
								<td>Multi-step forms, wizards, complex flows.</td>
							</tr>
							<tr>
								<td>
									<GatsbyLink to="/lab/components/Data Visualization/Progress Bar">
										Progress Bar
									</GatsbyLink>
								</td>
								<td>Shows overall progress of a single process.</td>
								<td>Indicates how much of a task is complete.</td>
								<td>File uploads, loading content.</td>
							</tr>
						</tbody>
					</DocsTable>
					<Header.H2>Examples</Header.H2>
					<Paragraph>
						A visual component that displays progress towards multiple goals or targets within a
						single container. It utilizes stacked segments to represent each goal's progress and
						overall completion status.
					</Paragraph>
					<Paragraph>
						<b>Composition</b>:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Container</b>: The overall bounding box of the component.
							</>,
							<>
								<b>Segments</b>: Individual bars or sections representing each goal or target.
							</>,
							<>
								<b>Label</b> (recommended): Text elements that describe the segments.
							</>,
							<>
								<b>Values</b> (recommended): Numerical or percentage indicators of progress for each
								segment.
							</>,
						]}
					/>
					<Header.H3>Basic usage</Header.H3>
					<Paragraph>
						This showcases the progress bar in its most common form, with filled segments indicating
						completed steps and an empty segment representing the step currently in progress.
					</Paragraph>
					<Example fn={BasicUsageMultiple} />

					<Header.H3>Empty</Header.H3>
					<Paragraph>
						This illustrates the progress bar's initial state, where no steps have been completed
						yet. All segments are in the "empty" or "inactive" state.
					</Paragraph>
					<Example fn={Empty} />

					<Header.H3>Sizes</Header.H3>
					<Paragraph>
						This demonstrates the flexibility of the multi-segment progress bar, showing how it can
						adapt to different heights and widths to fit within various layouts and design
						requirements.
					</Paragraph>
					<Example fn={Sizes} />

					<Header.H3>Custom segment styles</Header.H3>
					<Paragraph>
						This highlights the ability to customize the appearance of individual segments. Here,
						you can see different colors, patterns, or textures applied to segments, allowing for
						greater visual differentiation and customization.
					</Paragraph>
					<Example fn={CustomStyle} />

					<Knobs
						component={MultiSegmentProgressBar}
						initialProps={() =>
							({
								total: 100,
								segments: [
									{ value: 15, color: ColorGreen, tooltip: "15 out of 100" },
									{ value: 40, color: ColorBlue, tooltip: "40 out of 100" },
								],
								disableInitialAnimation: false,
								size: "medium",
							} as MultiSegmentProgressBarProps)
						}
					/>

					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Header.H4>General</Header.H4>
						<Paragraph>
							Use <Code>MultiSegmentProgressBar</Code> when
						</Paragraph>
						<Ul
							items={[
								"The process has clearly defined, sequential steps or phases.",
								"You want to show progress within each step, in addition to overall progress.",
								"The user benefits from knowing which step is currently active.",
								<>
									Visualizing the distribution of resources across different categories (e.g.,
									budget allocation, team workload).
								</>,
							]}
						/>
						<Header.H4>Placement</Header.H4>
						<Paragraph>
							<Code>MultiSegmentProgressBar</Code> is typically used in the following places:
						</Paragraph>
						<Ul
							items={[
								<> Within Card: To indicate progress within a specific task or process.</>,

								<>
									Form/Wizard Completion: Indicating progress through multi-step forms or processes.
								</>,
							]}
						/>

						<Header.H4>Style</Header.H4>
						<Ul
							items={[
								<>
									<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
									typography, and spacing. If you are not using a component from Fancy, match the
									styling of your <Code>MultiSegmentProgressBar</Code> to existing components for
									visual consistency.
								</>,
								<>
									The primary goal is to communicate progress clearly and quickly. Avoid cluttering
									the component with excessive details or unnecessary visual elements.
								</>,
								"Use distinct colors for each segment to clearly differentiate them.",
								<>
									Use a consistent color scheme that aligns with overall design system. See{" "}
									<a href="https://fancy.siteimprove.com/design-tokens/">Design tokens</a>.
								</>,
								"Clearly label each segment to indicate its purpose.",
								<>Avoid overwhelming users with too many segments. Aim for 5-7 segments or less.</>,
								<>Make sure segments are wide enough to be easily distinguishable.</>,
								<>
									Segment order: Arrange segments logically. Options include:
									<Ul
										items={[
											"Most important first: Place the most critical goal or largest segment at the top.",
											"Sequential: Order segments to match the workflow or process they represent.",
										]}
									/>
								</>,
								<>Place labels in a way that avoids visual clutter and maintains readability.,</>,
								<>
									Value Indicators (Within Tooltip):
									<Ul
										items={[
											"Clear values: Display values using a legible font size and format.",
											"Percentage vs. Absolute: Consider which format is most meaningful for your users.",
										]}
									/>
								</>,
							]}
						/>
						<Header.H4>Interaction</Header.H4>
						<Ul
							items={[
								<> Tooltips: Provide additional details on hover or focus for each segment.</>,
								<>
									Responsiveness: Ensure the component adapts gracefully to different screen sizes
									and orientations.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul
							items={[
								<>
									The process has no clear steps or phases. Use{" "}
									<GatsbyLink to="/lab/components/Data Visualization/Progress Bar">
										Progress Bar
									</GatsbyLink>{" "}
									.
								</>,
								<>
									The number of steps is very large ({">"}10). Consider alternative visualizations.
								</>,
								<>The steps are non-linear or can be completed in any order.</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Header.H4>For designers</Header.H4>
						<Ul
							items={[
								"Ensure sufficient color contrast between segments and background.",
								"Provide clear text descriptions (visible or via ARIA) for each segment.",
							]}
						/>
						<Header.H4>For developers</Header.H4>
						<Paragraph>
							This component comes with built-in accessibility, no extra work required.
						</Paragraph>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul
							items={[
								"Keep labels concise and informative.",
								"Avoid jargon or overly technical language.",
							]}
						/>
					</InlineMessage>
				</TextContainer>
			</ContentSection>
		</>
	);
};

const BasicUsageMultiple = () => (
	<MultiSegmentProgressBar
		size={"medium"}
		total={240}
		segments={[
			{ value: 15, color: ColorGreen, tooltip: "15 out of 240" },
			{ value: 10, color: ColorBlue, tooltip: "10 out of 240" },
			{ value: 25, color: ColorRed, tooltip: "25 out of 240" },
			{ value: 35, color: ColorYellow, tooltip: "35 out of 240" },
			{ value: 70, color: ColorPink, tooltip: "70 out of 240" },
			{ value: 5, color: ColorPurple, tooltip: "5 out of 240" },
			{ value: 0, color: ColorBlack, tooltip: "0 out of 240" }, // this one is not rendered, because value: 0
		]}
	/>
);

const Empty = () => <MultiSegmentProgressBar size={"medium"} total={240} segments={[]} />;

const Sizes = () => (
	<div>
		<div style={{ marginTop: "0.625rem" }}>
			<H4>Small</H4>
			<MultiSegmentProgressBar
				size={"small"}
				total={500}
				segments={[
					{ value: 300, color: ColorBlue, tooltip: "300 out of 500" },
					{ value: 35, color: ColorYellow, tooltip: "35 out of 500" },
				]}
			/>
		</div>
		<div style={{ marginTop: "0.625rem" }}>
			<H4>Medium</H4>
			<MultiSegmentProgressBar
				size={"medium"}
				total={900}
				segments={[
					{ value: 300, color: ColorBlue, tooltip: "300 out of 900" },
					{ value: 35, color: ColorYellow, tooltip: "35 out of 900" },
				]}
			/>
		</div>
		<div style={{ marginTop: "0.625rem" }}>
			<H4>Large</H4>
			<MultiSegmentProgressBar
				size={"large"}
				total={700}
				segments={[
					{ value: 300, color: ColorBlue, tooltip: "300 out of 700" },
					{ value: 35, color: ColorYellow, tooltip: "35 out of 700" },
				]}
			/>
		</div>
	</div>
);

/**
 * For some unknown reason, the dash border pattern produced by the testing
 * tool is not stable and changes between runs. So we have to disable this
 * test for now.
 * @ignore [visual]
 */
const CustomStyle = () => (
	<MultiSegmentProgressBar
		size={"large"}
		total={100}
		segments={[
			{ value: 10, color: ColorGreen, tooltip: "10 out of 100" },
			{
				value: 80,
				color: ColorGreenLighter,
				tooltip: "80 out of 100",
				style: {
					border: `dashed ${ColorGreen}`,
					borderWidth: "2px 2px 2px 0",
				},
				/* or use
				className: "someClassName"
				*/
			},
		]}
	/>
);
