import React from "react";
import { cn, DataObserveKey, SrOnly, Tooltip, VisualComponent } from "@siteimprove/fancylib";
import * as scss from "./multi-segment-progress-bar.scss";

/** A colored segment on the progress bar */
export type ProgressBarSegment = {
	/** The value determines, together with the total, how much space a segment takes up in the progress bar */
	value: number;
	/** A CSS color value */
	color: string;
	/** The content of a tooltip. Tooltip is non-optional to support accessible input */
	tooltip: string;
	/** A CSS class to apply custom styles on the segment bar */
	className?: string;
	/** A style object to apply custom inline styles on the segment bar */
	style?: React.CSSProperties;
};

export type MultiSegmentProgressBarProps = {
	/** The maximum total value of all segments. This determines the scale of the progress bar. */
	total: number;
	segments: ProgressBarSegment[];
	size?: "small" | "medium" | "large";
	/** If true, there is no animation on first render. If values change after first render, there is an animation. */
	disableInitialAnimation?: boolean;
} & DataObserveKey &
	VisualComponent;

export type ColorGradientMap = { [offset: number]: string };

export function MultiSegmentProgressBar(props: MultiSegmentProgressBarProps): JSX.Element {
	const { total, size = "medium", disableInitialAnimation, className, style } = props;

	// Remove segments with value 0, so we get the border radius logic right
	const segments = props.segments.filter((s) => s.value > 0);

	const valueSum: number = segments.reduce((prev, current) => {
		return prev + current.value;
	}, 0);

	const calculatedWidth: number = (valueSum / total) * 100;

	const getBorderRadius = (listLength: number, index: number) => {
		if (listLength === 1) return scss.singleSegment;
		if (index === 0) return scss.firstSegment;
		if (index === listLength - 1) return scss.lastSegment;
		return scss.middleSegment;
	};

	return (
		<div className={cn(scss.progressBar, scss[size], className)} style={style}>
			<div
				role={"list"}
				aria-hidden={segments.length === 0}
				className={cn(scss.segmentsWrapper, !disableInitialAnimation && scss.initialAnimation)}
				ref={(el) => {
					if (el) {
						el.style.setProperty("--width", `${calculatedWidth.toString()}%`);
					}
				}}
			>
				{segments.map((segment, i) => {
					return (
						<Segment
							key={i}
							segment={segment}
							total={total}
							className={getBorderRadius(segments.length, i)}
						/>
					);
				})}
			</div>
			{segments.length === 0 && <SrOnly>Empty progressbar</SrOnly>}
		</div>
	);
}

function Segment(props: {
	segment: ProgressBarSegment;
	total: number;
	className?: string;
}): JSX.Element {
	const { segment, className } = props;

	return (
		<div
			role={"listitem"}
			style={{
				backgroundColor: segment.color,
				flex: segment.value,
				transition: "background-color 300ms",
				position: "relative",
				...segment.style,
			}}
			className={cn(className, segment.className)}
		>
			<Tooltip
				style={{ position: "absolute", width: "100%", height: "100%" }}
				variant={{ type: "icon-only" }}
				content={segment.tooltip}
			/>
		</div>
	);
}
